



/* Variable for Color */

:root {
  --black: #000000;
  --brand: #8129CE;
  --brandLight: #EDD1FF;
  --brandHover: #A33AE7;
  --disabled: #C5C3C566;
  --subHeading: #C5C3C520;
  --fontDisabled: #363636;
}

.ag-theme-alpine {
  --ag-foreground-color: var(--black) !important;
  --ag-background-color: #FFF !important;
  --ag-header-foreground-color: rgb(0, 0, 0) !important;
  --ag-header-background-color: var(--brandLight) !important;
  --ag-odd-row-background-color: #ffffff !important;
  --ag-alpine-active-color: var(--brand) !important;
  --ag-selected-row-background-color: #3d21f34d !important;
  --ag-row-hover-color: var(--brandLight) !important;
  --ag-border-color: #0000007a !important;
  --ag-secondary-border-color: var(--black) !important;

  --ag-font-size: 17px;
  --ag-font-family: Poppins !important;
  --ag-icon-font-family: agGridAlpine !important;
}

.ag-icon {
  font-family: agGridAlpine !important;;
}

.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.ag-theme-alpine {
  --ag-background-color: #ddd;
}

.ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: unset !important;
}

.ag-theme-alpine .ag-header-cell-label {
  font-style: unset !important;
  width: 100%;
  display: flex;
  justify-content: center !important;
}

.ag-header-cell, .ag-header-group-cell {
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
  justify-content: center;
}

.ag-cell {
  /* padding-left: unset !important;
  padding-right: unset !important; */
  border-right: 1px solid var(--black) !important;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;

}

.ag-cell-wrap-text {
  word-break: break-word !important;
}
/* 
.ag-cell:first-child {
  border-left: 1px solid var(--black) !important;
}

.ag-row {
  border-bottom: unset !important;
  border-top: 1px solid var(--black) !important;

}

.ag-row:not(last-child) {
  border-top: 1px solid var(--black) !important;
}

.ag-row:last-child {
  border-bottom: 1px solid var(--black) !important;

} */

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 1.5px solid;
  border-color:  var(--brand) !important;
  border-style: pink !important;
  outline: initial;
}

.t360-fin-ag-grid-disabled-cell {

  background-color: var(--disabled) !important;
  font-weight: 600;
  color: var(--fontDisabled) !important;
}

.t360-fin-ag-grid-acc-grey {

  background-color: var(--disabled) !important;
}



.t360-fin-pipe-bill-fixed-col {

  background-color: var(--brandLight) !important;
  font-weight: 600;
  color: var(--fontDisabled) !important;
  border-bottom: 1px solid black !important;

}

.t360-fin-ag-grid-bizops-label-cell {
  background-color: #A9A9A966;
}

/* .ag-row-selected::before, .ag-row-hover.ag-full-width-row.ag-row-group::before, .ag-row-hover:not(.ag-full-width-row)::before {
  content: unset !important;
} */

/* .ag-row-selected .ag-cell:first-child::before, .ag-row-hover.ag-full-width-row.ag-row-group .ag-cell:first-child::before, .ag-row-hover:not(.ag-full-width-row) .ag-cell:first-child::before {
  content: "";
  background-color: var(--ag-selected-row-background-color);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 0 !important;
  border-right: 1px solid black !important;
  /* border-top: 1px solid black !important;;
  border-bottom: 1px solid black !important;;
  border-left: 1px solid black !important;; */
  /* border: 1px !important; */
  /* margin-right: 1px !important; */
  border-color: unset;
  border-style: unset;
  outline: unset;

  background-color: rgba(186, 186, 186, 0.395);
}

.ag-ltr .ag-cell-focus:has(.t360-fin-ag-empty-right-border):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:has(.t360-fin-ag-empty-right-border),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:has(.t360-fin-ag-empty-right-border):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:has(.t360-fin-ag-empty-right-border),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 0 !important;
  /* border-right: 1px solid black !important; */
  /* border-top: 1px solid black !important;;
  border-bottom: 1px solid black !important;;
  border-left: 1px solid black !important;; */
  /* border: 1px !important; */
  /* margin-right: 1px !important; */
  border-color: unset;
  border-style: unset;
  outline: unset;

  background-color: rgba(186, 186, 186, 0.395);
}

.ag-cell-focus:has(.t360-fin-ag-empty-right-border) {
  border-right: unset !important;
}

/* .t360-pipeline-table-con .ag-row:nth-last-child(-n+4) {
  border: none;
}

.t360-pipeline-table-con .ag-row:nth-last-child(-n+4) .t360-fin-ag-grid-disabled-cell {
  border-bottom: 1px solid black;
} */

/* .t360-pipeline-table-con .ag-row:nth-last-child(-n+3) .t360-fin-ag-grid-disabled-cell:first-child {
  border-left: 1px solid black;
} */

.t360-no-right-border {
  border-right: unset !important;
}

.t360-only-right-border {
  border-left: 1px solid black !important;
}

/* .t360-acc-level-sum-table-con .ag-row:last-child {
  border: none;
}

.t360-acc-level-sum-table .ag-row:last-child .t360-fin-ag-grid-disabled-cell {
  border-bottom: 1px solid black;
} */

.t360-ops-doc-temp-icon {
  padding-left: 4px;
  width: 30px;
}


div[class^='gridFileEntryPreview-'], div[class*=' gridFileEntryPreview-']{
  min-height: 100px;
  max-height: 100px;
}

div[class^='gridFileEntryNameContainer-'], div[class*=' gridFileEntryNameContainer-']{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div[class^='selectionIndicator-'], div[class*=' selectionIndicator-']{
  background: #96959636;
}

div[class^='focusIndicator-'], div[class*=' focusIndicator-']{
  background: #96959636;
}

div[class^='folderFrontSide-'], div[class*=' folderFrontSide-']{
  background: #f6b819;
  background: linear-gradient(5deg, rgba(246,184,25,1) 0%, rgba(250,190,36,1) 75%, rgba(250,198,53,1) 100%);
}

div[class^='folderBackSideTop-'], div[class*=' folderBackSideTop-']{
  background: #fcbd1b;
}

div[class^='folderBackSideMid-'], div[class*=' folderBackSideMid-']{
  background: #fcbd1b;
}

.fa-folder path{
  fill: #fcbd1b;
}

/* .ag-center-cols-viewport {
  overflow-x: hidden !important;
} */


