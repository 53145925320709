@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins' !important;
}

legend {
  width: auto;
}

.MuiTypography-root {
  color: #707070;
}

body {
  background-color: #F7F7F7;
}

.fc a {
  color: var(--brand);
  text-decoration: none;
}

.fc a:hover {
  color: var(--brandHover);
}

button.fc-prev-button.fc-button.fc-button-primary {
  margin-right: 10px;
}

ul li { padding: 15px 0px; }

.t360-modal-con {
  display: flex;
  flex-direction: column;
}

.t360-modal-content-text {
  text-align: left;
  font-family: sans-serif;
  letter-spacing: 0px;
  color: #606060;
  opacity: 1;
}

.t360-modal-content-text-email {
  min-width: max-content;
}

.t360-modal-content-fa-icon {
  font-size: 15px;
  color: #46b346;
}

.t360-modal-content-icon-con {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: sans-serif;
  font-size: 0.8em;
}

.t360-modal-content-button-delete {
  margin-left: 10px;
}

.modal-body {
  padding: 0 20px;
}

.modal-header {
  border-bottom: none;
  justify-content: flex-end;
  padding: 0 10px;
  border-bottom: unset !important;
  padding: 10px !important;

}

.modal-header button {
  border: none;
}

.modal-header button span {
  font-size: 25px;
}

/* Support page start */

.t360-support-custom-customInput {
  border-radius: 4;
  font-size: 14;
  padding: 6px 12px;
}
.t360-support-custom-singleInput {
  border-radius: 4;
  font-size: 14;
  padding: 8px 12px;
}
.t360-support-custom-customSelect {
  padding: 10px 26px 10px 12px;
}

.t360-support-custom-multilint-custom-in {
  border-radius: 4;
  font-size: 14;
  line-height: 20px;
}
.t360-support-custom-multilint-custom-out {
  border-radius: 4;
  font-size: 14;
  line-height: 20px;
  padding: 12px;
}

/* Create Venture start */

.t360-create-venture-next-con {
  /* display: flex;
  flex-direction: column !important;
  justify-content: space-between; */
  width: 100%;
  height: 70%;

}


.t360-app-content-with-side-bar-con {
  display: flex;
  justify-content: space-evenly;
}

.t360-toast-close-btn-style {
  color: #FFF;
  cursor: pointer;
  font-size: 21px;
}

.t360-fin-con {
  margin-left: unset !important;
  padding-left: unset !important;
  display: unset;
}

.t360-fin-con-header {
  margin-left: unset !important;
  padding-left: unset !important;
  margin-right: unset !important;
  padding-right: unset !important;
  display: contents;
}

.t360-gtm-stg-alli-button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.t360-app-content {
  width: 100%;
  margin-right: 2vw !important;
}

.t360-cell-range-selected::before {
  content: "";
  background-color: var(--ag-header-background-color);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.ag-header-group-cell-label {
  display: flex;
  justify-content: center;
}

.ag-row-hover .t360-cell-sno::before {
  content: "";
  background-color: var(--ag-header-background-color);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.t360-dash-image {
  height: 100%;
  width: 75%;
}

.t360-dash-box-con-title {
  font-size: "1.2vw";
  font-weight: "bold";
}

.t360-withside-bar-layout-con {
  padding: 25px 30px;
  overflow: auto;
  height: 100%;
}


.t360-dash-box-con-content {
  font-size: "1vw";
  margin-top: "10px";
  text-align: "justify";
}

.box-display-style-for-next-and-back-button-with-justify {

  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;

}

.box-indiv-header-con{
  padding: 30px 60px 0px;
}

.grid-nav-dash{
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-evenly;
}

.t360-back-next-button-con{
  padding: 0px 60px 0px;
  margin-bottom: 16px;
}

.t360-fin-disabled-row {
  background-color: var(--disabled) !important;
}

@media (min-width: 1080px) {

  .t360-fin-acc-growth-per-con-charts {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    font-size: 10px;
  }

  .t360-fin-chart-head-text {
    font-size: 1.1em;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 600;
    color: #707070;
  }

}

/* @media (min-width: 1440px) {

  .t360-dash-image {
    height: 100%;
    width: 100%;
  }

} */
@media (min-width: 1024px) {

  .t360-fin-acc-growth-per-con-charts {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    font-size: 7px;
  }

  .t360-fin-chart-head-text {
    font-size: 1em;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 600;
    color: #707070;
  }

}

.ag-cell:has(.t360-fin-ag-empty-right-border) {
  border-right: unset !important;
}

.t360-fin-ag-grid-remove-bottom {
  border-bottom: unset !important;
}

.t360-fin-ag-grid-only-bottom {
  border-bottom: 1px solid black !important;
}

.t360-fin-chart-head {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  justify-content: center;
  min-height: 60px;
}

.t360-fin-chart-head-text {
  font-size: 1.3em;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 600;
  color: #707070;
}

.t360-fin-chart-year {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
}

.t360-fin-chart-con {
width: 100%;
display: flex;
justify-content: center;
margin: auto;
}

.t360-fin-chart-con-row {
  margin-top: 20px;
  display: flex;
  width: 100%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 15px;
  justify-content: center;
}

.t360-fin-chart-con-col {
  width: 50%;
  padding: unset;
  margin: 25px 0px;
}

.t360-fin-chart-con-col-last {
  width: 100%;
  margin: 25px 0px;
}

.t360-fin-chart-con-row-btwn {

  margin-top: 80px;
  display: flex;
  justify-content: space-between;

}

.t360-fin-chart-pop-up {
  padding-left: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  height: 80vh;
}

.t360-fin-chart-pop-up-close-btn {
  display: "flex";
  justify-content: "flex-end";
  background: #46b346;
}

.t360-fin-edit-start-year {
  margin: 0px 30px 30px 30px;
}

.t360-fin-edit-start-year-heading {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

.t360-fin-edit-start-year-text {
  margin-bottom: 20px;
}
.t360-fin-edit-start-year-btn {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t360-fin-insights-info {
  font-size: 1em;
  font-weight: 400;
  font-style: italic;
  margin-left: 10px;
}

.t360-fin-insights-info-link {
  color: var(--brand);
  text-decoration: none;
  cursor: pointer;
}

.t360-fin-insights-info-link:hover {
  color: var(--brand);
  text-decoration: none;
}
.t360-fin-edit-start-year-warn {
  color: red;
}

.t360-fin-ag-grid-cat-heading {
  text-transform: uppercase;
  justify-content: left !important;
  font-weight: bold;
  background-color: var(--disabled);
}

.t360-fin-ag-grid-cat-sub-heading {
  text-transform: capitalize;
  justify-content: center !important;
  background-color: var(--subHeading);
}

.t360-fin-ag-grid-cat-sub-heading-tot {
  text-transform: capitalize;
  justify-content: center !important;
  font-weight: bold;
  background-color: var(--disabled);
}

.custom-tooltip {
  width: 100%;
  height: 70px;
  border: 1px solid cornflowerblue;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.t360-fin-pipe-group-header {
  display: flex;
  width: 100%;
  justify-content: center;
}

.t360-fin-acc-growth-per-con {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 20px; */
}

.t360-fin-acc-growth-per-con-margin {
  margin-right: 5px;
  font-weight: 500;
}

.t360-fin-acc-growth-per-con-charts {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 12px;
}

.t360-tooltip-hyperlink {
  color: #ffffff !important;
  text-decoration: underline;
}

.t360-support-link {
  color: var(--brand) !important;
  text-decoration: none;
  font-weight: 700;
}

.t360-light-tooltip-hyperlink {
  color: #FF7F00 !important;
  text-decoration: underline;
}

.t360-fin-on-roll-cus-header {
  align-items: center;
  display: flex;
  justify-content: center;
}

table {
  font-family: 'Poppins' !important;
  border-collapse: collapse;
}

th {
  border: 1px solid #000;
  text-align: left;
  padding: 5px 15px;
}


.t360-box-gtm-digital-cont-create-loader{
  width: 15vw;
  height: 30vh;
  background: #00000024;
  display: flex;
  justify-content: center;
  align-items: center;

}


.t360-box-gtm-digital-cont-loader{
  width: 100%;
  height: 40vh;
  background: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}
.t360-box-sub-main-sec-loader{
  width: 100%;
  height: 67vh;
  background: #00000024;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}

.t360-box-loader{
  width: 100%;
  height: 67vh;
  /* background: #00000024; */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid white 11px;
  border-right: solid white 11px;

}
.t360-loader-con{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000004f;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid white 11px;
  border-right: solid white 11px;

}

.t360-biz-serv-off-loader-con{
  position: absolute;
  width: 85%;
  height: 57vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid white 11px;
  border-right: solid white 11px;

}
.t360-white-loader-con{
  position: absolute;
  width: 85%;
  height: 57vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid white 11px;
  border-right: solid white 11px;

}
.t360-welcome-white-loader-con{
  height: 57vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid white 11px;
  border-right: solid white 11px;

}

.t360-loader-con-charts {
  top: 0;
  width: 100%;
  height: 100%;
  border-right-width: 0px;
  border-left-width: 0px;
}

/* NO BORDER SPINNER */
.nb-spinner {
  width: 7vw;
  height: 7vw;
  background: transparent;
  border-top: 6px solid var(--brand);
  border-right: 6px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.t360-gtm-guide-head {
  font-size: 1.4rem !important;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.t360-gtm-prob-solu-first-tooltip {
  max-width       : 500px;
  font-size       : 10px;
  background-color: white;
  color          : #707070;
  box-shadow      : 0px 3px 6px #00000029;
  border         : 1px solid #707070;
  border-radius   : 10px;
  white-space     : pre-wrap;
}

.t360-gtm-prob-solu-first-indi-box {
  border         : 1px solid #707070;
  border-radius   : 13px;
}

.t360-gtm-prob-solu-first-indi-box-hypo {
  border         : 1px solid #707070;
  border-radius   : 13px;
  height: 80%;
}

.t360-gtm-prob-solu-que-con {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid var(--brand);
  border-radius: 10px;
}

.t360-gtm-prob-solu-que-con-hypo {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--brand);
  border-radius: 10px;
}

.bottom-margin-10 {
  margin-bottom: 10px;
}

.t360-gtm-prob-solu-first-quest {
  border-radius: 10px;
  font-size    : 14px !important;
  font-weight  : bold !important;
  height      : 70px;
}

.t360-gtm-prob-solu-first-help-icon {
  color   : var(--brand);
  font-size: 18px;
}

.t360-gtm-buyer-jrn-question {
    border       : 1px solid var(--brand);
    border-radius: 10px;
    padding      : 15px 19px;
    font-size    : 14px !important;
    font-weight  : bold !important;
    /* padding-right: 0px; */
    height       : 7vh;
    box-sizing   : unset;
}

.t360-gtm-buyer-jrn-con-box {
  width       : 30%;
  display: flex;
  align-items: flex-start;
}

.t360-gtm-buyer-jrn-indi-box {
  box-shadow   : 0px 3px 6px #00000029;
  border      : 1px solid #CCCCCC;
  border-radius: 10px;
  width       : 100%;
  /* display: flex;
  align-items: flex-start; */
}

.t360-gtm-buyer-jrn-chevron {
  /* position: absolute; */
  width   : 40px;
  /* top     : 5%; */
  /* left    : 87%; */
  /* z-index  : 100; */
  margin-top: 15px;
}

/* Positioning Module*/

.over-flow-unset {
  overflow: unset !important;
}

.position-relative {
  position: relative !important;
}

.t360-gtm-post-body-sec {
  overflow: auto;
  height: 58vh;
  padding: 20px;
}

.t360-gtm-post-body-sec::-webkit-scrollbar {
  width: 5px;
}
.t360-gtm-post-body-sec::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.t360-gtm-post-body-sec::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}


.t360-gtm-post-box-grid {
  height       : 75%;
  align-content: center;
  margin-bottom: 16px;
  margin-top   : 16px;
}

.t360-gtm-post-indi-head {
  font-size  : 17px !important;
  color      : #393939;
  font-weight: bold !important;
  height: 10vh;
}

.t360-gtm-post-indi-desc {
  font-size  : 14px !important;
  color      : #393939;
  margin-top   : 20px !important;
}

.t360-gtm-post-box-img {
  width  : 70px;
  background: #FFF;
}

.t360-gtm-post-arrow-icon {
  position : absolute;
  top      : 16%;
  left     : 93%;
  color    : var(--brand);
  font-size: 50px !important;
  z-index  : 100;
}

/* .t360-gtm-post-usecase-addbtn {
  background-color: var(--brand) !important;
  text-transform  : capitalize;
  border-radius   : 10px !important;
} */

.t360-gtm-post-head-typo {
  text-align : center !important;
  color      : #393939 !important;
  font-weight: bold !important;
}

.t360-gtm-post-double-chevron-icon {
  position: absolute;
  top     : 50%;
  width   : 30px;
  left    : 100%;
}

.t360-gtm-post-firstgrid-item {
  position: relative;
}

.t360-gtm-post-research-question {
  color    : "#393939"  !important;
  font-size: 15px !important;
}

.t360-gtm-dig-mark-ques-align {
  display: flex;
  align-items: center;
}

.t360-gtm-digi-mark-select {
  color:#000;
}

.t360-gtm-sales-pipe-aggrid-con {
  height: 100%;
  position: relative;
  /* width: 100%; */
}

.t360-gtm-sales-pipe-con {
  height: 100%;
  overflow: auto;
}

.t360-gtm-sales-pipe-loader-con {
  position: absolute;
  height: 51vh;
  background: #0000004f;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid white 11px;
  border-right: solid white 11px;
}

.t360-biz-dash-con {
  row-gap: 64px;
  margin-top: 32px;
}

.t360-biz-package-con-body {
  padding: 0px 35px 35px 35px;
  overflow: auto;
}

@media (max-height: 870px) {

  .t360-gtm-sales-pipe-loader-con {
    position: absolute;
    height: 43.5vh;
    background: #0000004f;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid white 11px;
    border-right: solid white 11px;
  }

  .t360-gtm-post-box-grid {
    height       : 75%;
    align-content: center;
    margin-bottom: 16px;
    margin-top   : 16px;
  }
  .t360-biz-dash-con {
    row-gap: 16px;
    margin-top: 16px;
  }
  .t360-gtm-post-body-sec {
    overflow: auto;
    height: 52vh;
    padding: 20px;
  }
}

.edit-persona-popup-ques-ans-con {
  margin-bottom: 15px;
}

.user-persona-details-que-ans-con {
  max-height: 80px;
  overflow: hidden;
}
.t360-gtm-sales-chart-con {
  margin: 10px 20px;
  height: 80vh;
  overflow: auto;
}

.t360-gtm-sales-chart-con-heading {
  font-weight: 600 !important;
  font-size: 2rem !important;
  padding-left: 40px !important;
}

.t360-gtm-sales-chart-con::-webkit-scrollbar {
  width: 5px;
}
.t360-gtm-sales-chart-con::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.t360-gtm-sales-chart-con::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

.t360-gtm-sales-chart-con-row {
  margin: 20px;
  display: flex;
  justify-content: center;
  width: 97%;
  box-shadow: #0e1e251f 0px 2px 4px 0px, #0e1e2552 0px 2px 16px 0px;
  border-radius: 15px;
}

/* .t360-gtm-sales-chart-con-col {
  width: 49%;
} */

.t360-gtm-sales-chart {
  width: 50%;
  padding: 20px;
}

.t360-gtm-sales-chart-full {
  width: 100%;
  padding: 20px;
}

.t360-gtm-sales-chart-nodata {
  height: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

/* Business Solution */

.t360-biz-solu-pack-align-right {
  display: flex;
  justify-content: flex-end;
}

.t360-biz-solu-pack-align-right-cls-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 10px;
}

.t360-biz-package-save-con {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0px 20px;
  padding: 0px 25px;
}

.t360-light-tooltip {
  background-color: #FFF;
  /* box-shadow      : theme.shadows[1], */
  font-size       : 12px !important;
  color          : #FF7F00 !important;
  border         : 1px solid #FF7F00 !important;
  padding-top     : 5px !important;
  border-radius   : 10px !important;
  font-weight     : bold !important;
}

p.t360-ops-doc-temp-credits {
  font-size: 10px !important;
  color: #707070 !important;
}

a.t360-ops-doc-temp-credits-link {
  color: var(--brand) !important;
  text-decoration: none !important;
}

.t360-redirect-comment {
  color: var(--brand) !important;
}

@media (max-height: 870px){
  .t360-gtm-prob-solu-first-quest {
    font-size: 12px !important;
    font-weight: bold !important;
    padding-right: 0px;
}
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

.common-box-display-style-for-next-and-back-button{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 79vh;
  flex-wrap: nowrap;
}
.checkBox{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
  column-gap: 0px;
}

.t360-canvas-expense-plan-header{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.t360-canvas-expense-plan-mainContent .rdt_Table{
  overflow: auto !important;
  min-height: 17vh !important;
  max-height: 17vh !important;
  /* padding-right: 10px; */
}

.t360-display-flex {
  display: flex;
}

.t360-canvas-value-prop-content {
  padding-right: 10px;
}

.t360-canvas-list {
  /* transition: transform 0.2s ease-in-out; */
  font-size: 15px;
  font-weight: 600;
}

.t360-canvas-list:hover {
  margin-left: 10px;    
  color: #8120ce;
  /* transform: translateX(10px); */
}

.t360-canvas-icon {
  margin-right: 15px;
  font-size: 20px;
  transform: rotate(45deg);
}

.t360-canvas-list-item {
  font-size: 16px;
  font-weight: 600;
}

.no-data-message {
  display: flex;
  height: 17vh;
  align-items: center;
  justify-content: center;

}

/* For Digital content month view */

.t360-digi-content-calender .fc-daygrid-day-events {
  overflow: hidden;
}


.t360-buss-canvas-valu-prop-popup-para {
  white-space: pre-line;
  max-height: 70vh;
  overflow-y: auto;
}