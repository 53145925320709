#maingrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
}
#box1 {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 2vw 8vw;
}
#grid2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#loginbutton1 {
  text-transform: none;
  width: 12.5vw;
  height: 4vw;
  background: #8129CE;
  margin-left: 6vw;
  border-radius: 10px;
}
#dividerstyle {
  height: 33px;
  border-right-width: 2px;
  margin: 1.25vw;
  color: #707070;
}
#title1 {
  /* //shera launch */
  color: #8129CE;
  font-weight: 600;
  font-size: 1.8vw;
}
#textwithclick {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  color: #404040;
}
#loginbuttontext {
  font-size: 1.5vw;
  font-weight: medium;
  color: #ffffff;
}
#loginbutton {
  text-transform: none;

  width: 26vw;

  height: 4.1vw;
  background: #8129CE;
  width: 8vw;
  margin-left: 6vw;
}
#bigtext {
  font-size: 65px;
  font-weight: 900;
  text-align: left;
  color: #404040;
}
#grid3 {
  /* //do not change */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#gridbox1 {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
#gridbox2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#buttonorangecontained {
  /* //sign up button */
  text-transform: none;
  background: #8129CE;
  width: 19vw;
  margin-right: 2vw;
  height: 4.5vw;
  border-radius: 10px;
}

#buttonorangeoutlined {
  /* //login button */
  text-transform: none;
  background: #f8f9ff;
  color: #000000;
  width: 19vw;
  margin-right: 2vw;
  height: 4.5vw;
  border: 2px solid #8129CE;
  border-radius: 10px;
}
#textmediumwhite {
  font-weight: 400;
  color: #ffffff;
  font-size: 1.5vw;
}
#textmediumblack {
  font-weight: 400;
  color: #000000;
  font-size: 1.5vw;
}
#img {
  width: 30vw;
  height: 28.75vw;
  object-fit: contain;
}
